import React, { FC, useEffect, useState } from 'react';
import { TextButton, TextButtonPriority, Text } from 'wix-ui-tpa/cssVars';
import { CreditCard } from '@wix/wix-ui-icons-common';
import AmountInput from '../AmountInput/AmountInput';

export enum StoreCreditBalanceDataHooks {
  Container = 'StoreCreditBalanceDataHooks.Container',
  TextButton = 'StoreCreditBalanceDataHooks.TextButton',
  BalanceText = 'StoreCreditBalanceDataHooks.BalanceText',
  Icon = 'StoreCreditBalanceDataHooks.Icon',
}

export interface StoreCreditBalanceProps {
  storeCreditBalance: string;
  redeemStoreCreditText: string;
  codeValue?: string;
  onApplyCode: (code: string) => void;
  onRemoveCode: () => void;
  error?: string;
}

export enum CodeInputSectionDataHooks {
  EnterACodeButton = 'CodeInputSection.EnterACodeButton',
  InputSection = 'CodeInputSection.InputSection',
}

export const StoreCreditBalance: FC<StoreCreditBalanceProps> = ({
  storeCreditBalance,
  redeemStoreCreditText,
  codeValue,
  onApplyCode,
  onRemoveCode,
  error,
}: StoreCreditBalanceProps) => {
  const [isCollapsed, setIsCollapsed] = useState(!codeValue); // has SC left to redeem

  useEffect(
    () => {
      setIsCollapsed(!codeValue);
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [codeValue],
  );

  return (
    <div data-hook={StoreCreditBalanceDataHooks.Container}>
      <TextButton
        data-hook={StoreCreditBalanceDataHooks.TextButton}
        priority={TextButtonPriority.primary}
        prefixIcon={<CreditCard data-hook={StoreCreditBalanceDataHooks.Icon} />}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        <Text data-hook={StoreCreditBalanceDataHooks.BalanceText}>
          You have <span>${storeCreditBalance}</span> in store credit
        </Text>
      </TextButton>
      {!isCollapsed && (
        <AmountInput
          redeemStoreCreditText={redeemStoreCreditText}
          appliedValue={codeValue}
          onApply={onApplyCode}
          onRemove={onRemoveCode}
          error={error}
        />
      )}
    </div>
  );
};
