import React, { FC } from 'react';
import '@wix/design-system/styles.global.css';
import { LocaleKeysProvider } from '../../../../locale-keys/LocaleKeys';
import { useTranslation } from '@wix/yoshi-flow-editor';

const AppWrapper: FC<{
  Component: React.FC;
}> = ({ Component }) => {
  const { t } = useTranslation();

  return (
    <LocaleKeysProvider translateFn={t}>
      <Component />
    </LocaleKeysProvider>
  );
};

export default AppWrapper;
