import React, { FC, useEffect, useState } from 'react';
import { Button, ButtonPriority, TextField } from 'wix-ui-tpa/cssVars';
import { Box } from '@wix/design-system';

export enum AmountInputDataHooks {
  Container = 'amount-input-container',
  TextInput = 'amount-input-text-input',
  Button = 'amount-input-button',
}

export interface AmountInputProps {
  redeemStoreCreditText?: string;
  appliedValue?: string;
  onApply: (code: string) => void;
  onRemove: () => void;
  error?: string;
}

const AmountInput: FC<AmountInputProps> = ({
  redeemStoreCreditText,
  appliedValue,
  onApply,
  onRemove,
  error,
}: AmountInputProps) => {
  const [value, setValue] = useState(appliedValue ?? '');
  const [isApplied, setIsApplied] = useState(!!appliedValue);

  useEffect(() => {
    setValue(appliedValue ?? '');
    setIsApplied(!!appliedValue);
  }, [appliedValue]);

  const onButtonClick = () => {
    if (isApplied) {
      onRemove();
      /* istanbul ignore if */
      if (value !== appliedValue) {
        setValue('');
        setIsApplied(false);
      }
    } else {
      setIsApplied(true);
      onApply(value);
    }
  };

  return (
    <Box
      dataHook={AmountInputDataHooks.Container}
    >
      <TextField
        data-hook={AmountInputDataHooks.TextInput}
        placeholder={`$${redeemStoreCreditText}`}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onClear={() => setValue('')}
        successIcon
        withClearButton={!isApplied}
        disabled={isApplied}
        success={isApplied && !error}
        error={isApplied && !!error}
        errorMessage={error}
        newErrorMessage
      />
      <Button
        data-hook={AmountInputDataHooks.Button}
        upgrade
        fullWidth
        priority={ButtonPriority.basic}
        disabled={!value}
        onClick={onButtonClick}
      >
        {isApplied ? 'Remove' : 'Apply'}
      </Button>
    </Box>
  );
};

export default AmountInput;
