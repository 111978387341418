import React, { FC } from 'react';
import {
  useExperiments,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { Button } from 'wix-ui-tpa';
import settingsParams from '../settingsParams';
import { st, classes } from './Widget.st.css';
import App from '../components/App/App';
import AppWrapper from '../components/App/AppWrapper';

export type ControllerProps = {
  // Here, props passed via controller's `setProps` should be defined.
};

const Widget: FC<WidgetProps<ControllerProps>> = () => {
  const settings = useSettings();
  const { experiments } = useExperiments();

  return <AppWrapper Component={() => <App />} />;
};

export default Widget;
