import React, { FC } from 'react';
import { st, classes } from '../../Widget/Widget.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { Text, Button } from '@wix/design-system';
import { StoreCreditBalance } from '../StoreCreditBalance/StoreCreditBalance';

type AppProps = {};

const App: FC<AppProps> = () => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { t } = useTranslation();

  return (
    <StoreCreditBalance
      storeCreditBalance="80.00"
      redeemStoreCreditText="60.00"
      onApplyCode={() => undefined}
      onRemoveCode={() => undefined}
    />
  );
};

export default App;
